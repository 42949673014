import { Routes } from '@angular/router';
import { RoutePath } from '../router.config';
import { AuthGuard } from '../../guards/auth.guard';

export const ME_LAYOUT_ROUTE: Routes = [
  {
    path: RoutePath.roster_management,
    canActivate: [AuthGuard],
    data:{ pageId: ['roster-management'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/me/roster-management/roster-management.module').then((m) => m.RosterManagementModule),
  },
  {
    path: RoutePath.roster_slots,
    canActivate: [AuthGuard],
    data:{ pageId: ['roster-slots'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/me/roster-slots/roster-slots.module').then((m) => m.RosterSlotsModule),
  },
  {
    path: RoutePath.unavailability,
    canActivate: [AuthGuard],
    data:{ pageId: ['unavailability'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/me/unavailability/unavailability.module').then((m) => m.UnavailabilityModule),
  },
  {
    path: RoutePath.my_profile,
    canActivate: [AuthGuard],
    data:{ pageId: ['my-profile'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/me/my-profile/my-profile.module').then((m) => m.MyProfileModule),
  },
]
