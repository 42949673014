import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class HeightDataService {

    private navbarHeightSubject = new Subject<number>();
    private memberHeaderHeightSubject = new Subject<number>();

    navbarHeightChanged$ = this.navbarHeightSubject.asObservable();
    memberHeaderHeightChanged$ = this.memberHeaderHeightSubject.asObservable();

    getNavbarHeight(height: number) {
        this.navbarHeightSubject.next(height);
    }

    getMemberHeaderHeight(height: number) {
        this.memberHeaderHeightSubject.next(height);
    }

}