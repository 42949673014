import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { ApiURL } from "../api";
import { ViewNotification } from "../../shared/typings/app.typings";

@Injectable({
    providedIn: 'root'
  })
  export class NotificationService {

    constructor(private _httpService : HttpService ){}

    // get All notifications list
    getAllNotificationList(body:ViewNotification){
      const apiUrl = `${ApiURL.notification}/employee/get/all`
        return this._httpService.postAll(apiUrl,body)
    }

    // mark 
    acknowledge(id:string){
        const apiUrl = `${ApiURL.notification}/${id}/employee/acknowledge`
        return this._httpService.putOnlyUrlApi(apiUrl);
    }

    // get all employee list data
    getPushNotificationCount() {
      return this._httpService.getAll(`${ApiURL.notification}/employee/counts`)
    }
  }