import { Routes } from '@angular/router';
import { RoutePath } from '../router.config';
import { AuthGuard } from '../../guards/auth.guard';

export const DASHBOARD_LAYOUT_ROUTE: Routes = [
  {
    path: RoutePath.my_dashboard,
    loadChildren: () => import('../../../pages/modules/dashboard/my-dashboard/my-dashboard.module').then((m) => m.MyDashboardModule),
    canActivate: [AuthGuard],
    data:{ pageId: ['my-dashboard'], type:['child'] }
  },
  {
    path: RoutePath.today_task,
    loadChildren: () => import('../../../pages/modules/dashboard/today-task/today-task.module').then((m) => m.TodayTaskModule),
    canActivate: [AuthGuard],
    data:{ pageId: ['today-task'], type:['child'] }
  },
  {
    path: RoutePath.weight_stuck,
    loadChildren: () => import('../../../pages/modules/dashboard/weight-stuck/weight-stuck.module').then((m) => m.WeightStuckModule),
    canActivate: [AuthGuard],
    data:{ pageId: ['weight-stuck'], type:['child'] }
  },
  {
    path: RoutePath.sales_dashboard,
    loadChildren: () => import('../../../pages/modules/dashboard/sales-dashboard/sales-dashboard.module').then((m) => m.SalesDashboardModule),
    canActivate: [AuthGuard],
    data:{ pageId: ['sales-dashboard'], type:['child']  }
  },
  {
    path: RoutePath.calls_dashboard,
    loadChildren: () => import('../../../pages/modules/dashboard/calls-dashboard/calls-dashboard.module').then((m) => m.CallsDashboardModule),
    canActivate: [AuthGuard],
    data:{ pageId: ['calls-dashboard'], type:['child']  }
  },
  {
    path: RoutePath.opportunities_dashboard,
    canActivate: [AuthGuard],
    data:{ pageId: ['opportunities-dashboard'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/dashboard/opportunities-dashboard/opportunities-dashboard.module').then((m) => m.OpportunitiesDashboardModule),
  },
  {
    path: RoutePath.CSAT_dashboard,
    canActivate: [AuthGuard],
    data:{ pageId: ['CSAT-dashboard'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/dashboard/csat-dashboard/csat-dashboard.module').then((m) => m.CsatDashboardModule),
  },
  {
    path: RoutePath.referral,
    canActivate: [AuthGuard],
    data:{ pageId: ['referral'], type:['child'] },
    loadChildren: () => import('../../../pages/modules/manage-users/referral/referral.module').then((m) => m.ReferralModule),
  },
]
