import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { RoutePath } from 'src/app/core/config';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { CONSTANTS_TEXT } from 'src/app/core/const/app.constant';
import { LocalstorageService } from 'src/app/core/service/localstorage.service';
import { ApiResponse, ManageMemberFilter, NavBarMember, pagination, siblings, sildeMenu } from "../../../core/shared/typings/app.typings";
import { changedRouting } from 'src/app/core/shared/function/changedRouting';
import { redirectPage, searchWithCodeNumberName } from 'src/app/core/shared/function/function';
import { debounceTime, distinctUntilChanged, finalize, fromEvent, map, tap } from 'rxjs';
import { ManageMember } from 'src/app/core/shared/typings/manage-member.typings';
import { NavBarService } from 'src/app/core/service/commonService/nav-bar.service';
import { AuthService } from 'src/app/core/service/auth.service';
import { MoengageService } from 'src/app/core/service/commonService/moengage.service';
import { ViewNotificationComponent } from '../view-notification/view-notification.component';
import { ModelOpenService } from 'src/app/core/service/model-open.service';
import { getNotificationCount$ } from 'src/app/core/shared/@subject/subjects';
import { CallPopupComponent } from '../../modules/manage-users/manage-members/members-layout/members-layout/call-popup/call-popup.component';
import { HeightDataService } from 'src/app/core/service/commonService/height-data.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  @ViewChild(ViewNotificationComponent) viewNotificationComponent!: ViewNotificationComponent;
  @ViewChild('searching', { static: true }) search: ElementRef;
  @ViewChild('dynamicHeight', { static: false }) dynamicHeight: ElementRef;
  @Input() countMessage:number = 0;
  notificationCount: number;
  userId: string;
  userName: string;
  filterAdvanced: boolean = false;
  externalLink = RoutePath;
  sidebarMenu!: sildeMenu[];
  constant = CONSTANTS_TEXT;
  filterString: string = '';
  memberListData: ManageMember[] = []
  showSpin: boolean = false
  typing: boolean = false
  paginationData: pagination = { pageNumber: 1, pageSize: 10, length: 100 }
  filterObject: ManageMemberFilter = {}
  totalCount: any;
  lazyLoading: boolean = false
  profileImage: string
  modelTriggerEvent: boolean = false;

  constructor(
    private _localstorageService: LocalstorageService,
    private _changedRouting: changedRouting,
    private navBarService: NavBarService,
    private router: Router,
    private _authService: AuthService,
    private moengageService: MoengageService,
    private modelService: ModelOpenService,
    private heightDataService: HeightDataService
  ) {
    // if yes then call the api 
    this.modelService.dataModelClose$.subscribe((result: { status: boolean, id: string, type: string, data: any, }) => {
      if (result.status && result.type === CONSTANTS_TEXT.profileCall && this.modelTriggerEvent) {
        this.modelTriggerEvent = false;
      }
    });

    // Get user id from localstorage
    const userData = localStorage.getItem('User Data');
    if (userData) {
      const user = JSON.parse(userData);
      if(user.employeeDetails?.id && user.employeeDetails?.id != '') {
        this.userId = user.employeeDetails?.id;
      }
      this.userName = user.employeeDetails?.firstName;
      this.profileImage = user.employeeDetails?.profileImage
    }

    getNotificationCount$.subscribe((res: number) => {
      if (res) {
        this.notificationCount = res
      }
    });

  }

  ngOnInit(): void {
    this.sidebarMenu = this._changedRouting.submneu;
  }

  //make call
  makeCall(dataObject: any) {
    this.modelTriggerEvent = true
    this.modelService.openModel({ width: '600px', maxWidth: '96vw', maxHeight: '92vh', panelClass: 'modalLightGrayV4', data: { component: CallPopupComponent, data: dataObject } });
  }

  routing(prentUrl: string, siblingUrl: string, type: string, item: sildeMenu) {
    if (type === this.constant.parent) {
      let siblinngs: siblings | undefined = item.siblings.find((x: siblings) => {
        return x.permission
      })
      if (siblinngs) {
        // this._changedRouting.chnagedrouting(prentUrl, siblinngs.routerLink, '', '', this.constant.sibling, item)
        this._changedRouting.navigate(prentUrl, siblinngs.routerLink, '')
      }
    } else {
      // this._changedRouting.chnagedrouting(prentUrl, siblingUrl, '', '', type, item)
      this._changedRouting.navigate(prentUrl, siblingUrl, '')
    }
  }

  toggleFollowUp(): void {
    this.filterAdvanced = !this.filterAdvanced;
    if (this.filterAdvanced && this.viewNotificationComponent) {
      this.viewNotificationComponent.getAllNotification();
    }
  }

  logout() {
    this._authService.logout().subscribe((res: ApiResponse) => {
      if (res.success) {
        this._localstorageService.logout();
        this.moengageService.logout();
      }
      else {
        this._localstorageService.logout();
        this._localstorageService.showMessage(CONSTANTS_TEXT.error, res.message)
      }
    })
  }

  ngAfterViewInit(): void {

    const searchTerm = fromEvent<any>(this.search.nativeElement, 'keyup').pipe(
      map(event => event.target.value),
      tap(() => { this.typing = true }),
      debounceTime(500),
      distinctUntilChanged()
    );

    searchTerm.subscribe((res: string) => {
      if (res.trim() != '') {
        this.filterData(this.filterString)
      }
    });

    // Access the height of the dynamic element after the view has been initialized
    const rect: any = this.dynamicHeight.nativeElement.getBoundingClientRect();
    this.heightDataService.getNavbarHeight(rect.height);
    
  }

  // filter data by name , id and phone number
  filterData(event: string) {
    this.typing = false
    this.filterObject = {}
    if (event) {
      this.paginationData.pageNumber = 1
      this.filterObject = searchWithCodeNumberName(event)
      this.showSpin = true
      this.getMemberList(this.filterObject)
    }
  }

  getMemberList(filterData: ManageMemberFilter) {
    let payload: NavBarMember = { page: this.paginationData.pageNumber, limit: this.paginationData.pageSize }
    if (!_.isEmpty(filterData)) {
      if (filterData.name) {
        payload.name = filterData.name
      } else if (filterData.code) {
        payload.code = filterData.code
      } else if (filterData.phoneNumber) {
        payload.phoneNumber = filterData.phoneNumber
      }
    }
    this.navBarService.getMemberData(payload).pipe(finalize(() => { this.showSpin = false; this.lazyLoading = false })).subscribe((result: ApiResponse) => {
      if (result.success == true) {
        if (this.paginationData.pageNumber === 1) {
          this.memberListData = result.data;
        } else {
          this.memberListData = [...this.memberListData, ...result.data];
        }
        this.totalCount = result.pagination?.totalCount
      } else {
        this.memberListData = []
      }
    }, (error) => {
      this.memberListData = []
    })
  }

  // Client redirection to profile page
  clientView(id: string) {
    let manageUser = this._changedRouting.submneu.find((data: any) => data.name === "manage-users");
    let manage_members = manageUser?.siblings.find((data: any) => data.routerLink === "manage-members");
    let routerPath = manage_members?.siblings?.find((data: any) => data.permission === true);
    if (id) {
      redirectPage(id,routerPath?.routerLink)
    }
  }

  myProfile() {
    this.router.navigate([RoutePath.me + '/' + RoutePath.my_profile + '/' + RoutePath.view_profile, this.userId, 'view']);
  }

  onScroll() {
    if (this.totalCount > this.memberListData.length) {
      this.lazyLoading = true
      this.paginationData.pageNumber = this.paginationData.pageNumber + 1;
      this.getMemberList(this.filterObject)
    }
  }
}
