<div class="nty-filter text-center mt-1 mb-2">
    <div class="btn-group">
        <input type="radio" class="btn-check" name="btnradio" id="today" [(ngModel)]="selectedFilter" value="today">
        <label class="btn font-12 fw-500 py-2 px-3 ms-0" for="today">Today</label>

        <input type="radio" class="btn-check" name="btnradio" id="read" [(ngModel)]="selectedFilter" value="read">
        <label class="btn font-12 fw-500 py-2 px-3 ms-0" for="read">Read</label>

        <input type="radio" class="btn-check" name="btnradio" id="unread" [(ngModel)]="selectedFilter" value="unread">
        <label class="btn font-12 fw-500 py-2 px-3 ms-0" for="unread">Unread</label>
    </div>
</div>

<div class="notification-list" infinite-scroll [scrollWindow]="false" (scrolled)="onScrollDown()">
    <ul>
        <li *ngFor="let item of getFilteredNotifications(); index as i">
            <a href="javascript:void();" class="d-block" [ngClass]="{'bg-light-gray-v2': item.acknowledged == true}">
                <div class="media border-bottom py-2 px-2">
                    <div class="media-body d-flex justify-content-between">
                        <div class="nty-box">
                            <h6 class="nty-title font-13 fw-600">{{ i + 1 }}. {{ item.title }}</h6>
                            <p class="msg font-12 text-muted fw-500 mt-1">{{ item.description }}</p>
                            <small class="text-muted font-10 fw-500">{{ item.createdAt | date: 'dd-MMM-YYYY' }}</small>
                        </div>
                        <div class="action">
                            <button *ngIf="item.acknowledged == false"
                                class="btn bg-light-warning text-primary rounded-1 font-10 fw-600 py-1 px-2 ms-2"
                                (click)="markAsDone(item._id)">Mark as read</button>
                            <div *ngIf="item.acknowledged == true" class="text-center ms-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path fill="none" stroke="#53D477" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="1.5" d="m1.75 9.75l2.5 2.5m3.5-4l2.5-2.5m-4.5 4l2.5 2.5l6-6.5" />
                                </svg>
                                <small class="d-block text-muted font-10 fw-500">{{ item.acknowledgedAt | date:
                                    'dd-MMM-YYYY' }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        </li>
        <li *ngIf="noDataMessage" class="text-muted text-center mt-3">{{ noDataMessage }}</li>
    </ul>
</div>