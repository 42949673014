// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url:  process.env['DEV_URL'],
  assessment_url: process.env['ASSESSMENT_URL'],
  auth_url: process.env['AUTH_URL'],
  communication_url: process.env['COMMUNICATION_URL'],
  content_url: process.env['CONTENT_URL'],
  customer_url: process.env['CUSTOMER_URL'],
  diet_url: process.env['DIET_URL'],
  employee_url: process.env['EMPLOYEE_URL'],
  health_url: process.env['HEALTH_URL'],
  notifications_url: process.env['NOTIFICATIONS_URL'],
  pos_url: process.env['POS_URL'],
  payment_url: process.env['PAYMENT_URL'],
  order_url: process.env['ORDER_URL'],
  wellness_url: process.env['WELLNESS_URL'],
  dashboard_url: process.env['DASHBOARD_URL'],
  stream_api_key:process.env['STREAM_API_KEY'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
