export const CONSTANTS_TEXT = {
   signup:'signup',
   login:'login',
   success:'success',
   warning:'warning',
   error:'error',
   info:'info',
   image:'image',
   pdf:'pdf',
   video:'video',
   audio:'audio',
   excerciseThumbnail:'ExcerciseThumbnail',
   email:'email',
   verify_otp_wrong:'Please fill all digits',
   role:'User-Role',
   token:'Token',
   refresh:"refreshToken",
   text:'text',
   select:'select',
   number:'number',
   textarea:'textarea',
   time:"time",
   edit:'edit',
   view:'view',
   save:'save',
   date:"date",
   dateRange:"dateRange",
   password:"password",
   checkbox:'checkbox',
   radio:'radio',
   diet:'diet',
   exercise:'exercise',
   yoga:'yoga',
   activity:'activity',
   mind:'mind',
   copy:'copy',
   swap:'swap',
   delete:'delete',
   create: 'create',
   update: 'update',
   parent:'parentMenu',
   sibling:'siblingMenu',
   subchild:'subchild',
   removeDoctor:'removeDoctor',
   
   workout:'workout',
   workoutWeeklyCreate:'workoutWeeklyCreate',
   workoutWeeklyUpdate:'workoutWeeklyUpdate',
   dietdateformate:'DD MMM - ddd',
   weeklyadvancefilter:'weeklyadvancefilter',
   foodselectwarnig:'Please select Food!',
   dailydietModel:'dailyDietModel',
   weeklydietModel:'weeklydietModel',
   dietcreateModel:'dietcreatemodel',
   deleteweeklydiet:'deleteweeklydiet',
   dublicateweeklydiet:'dublicateweeklydiet',
   deletedailydiet:'deletedailydiet',
   dublicatedailydiet:'dublicatedailydiet',
   deleterecipediet:'deleterecipediet',
   daywisedeletediet:'daywisedeletediet',
   dayWiseDeleteWorkout:'dayWiseDeleteWorkout',
   RowWiseDeleteWorkout:'RowWiseDeleteWorkout',
   allowWeekDeleteWorkout:'allowWeekDeleteWorkout',
   allowWeekDeleteAssan:'allowWeekDeleteAssan',
   allowWeekDeleteActivity:'allowWeekDeleteActivity',
   dayWiseSwapWorkout:'dayWiseSwapWorkout',
   rowWiseSwapWorkout:'rowWiseSwapWorkout',
   dayWiseCopyWorkout:'dayWiseCopyWorkout',
   rowWiseCopyWorkout:'rowWiseCopyWorkout',
   rowWiseCopyAssan:'rowWiseCopyAssan',
   rowWiseCopyActivity:'rowWiseCopyActivity',
   weeklyWorkoutFilter:'weeklyWorkoutFilter',
   weeklyYogaFilter:'weeklyYogaFilter',
   yogaDateChanged:'yogaDateChanged',
   mindDateChanged:'mindDateChanged',
   createActivity:'createActivity',
   updateActivity:'updateActivity',
   singleDaydietImport:'singleDaydietImport',
   createYoga:'createYoga',
   updateYoga:'updateYoga',
   updateMind:'updateMind',
   createMind:'createMind',
   saveWeeklyMind:'saveWeeklyMind',
   updateWeeklyMind:'saveWeeklyMind',
   saveWeeklyYoga:'saveWeeklyYoga',
   updateWeeklyYoga:'updateWeeklyYoga',
   addTiming:'addTiming',
   daywiseCopy:'daywiseCopy',
   daywiseSwap:'daywiseSwap',
   rowwiseCopy:'rowwiseCopy',
   rowwiseSwap:'rowwiseSwap',
   dietdatechnaged:'dietdatechnaged',
   confirmationPublishDiet:'confirmationPublishDiet',
   confirmationPublishWorkout:'confirmationPublishWorkout',
   confirmationPublishYoga:'confirmationPublishYoga',
   confirmationPublishActivity:'confirmationPublishActivity',
   closedietSection:'closedietSection',
   mealswisedeletediet:'mealswisedeletediet',
   allWeekDelete:'allWeekDelete',
   importGuidelineWarning:"importGuidelineWarning",
   createGuideline:"createGuideline",
   createExerciseSet:"createExcerciseSet",
   updateExcerciseSet:"updateExcerciseSet",
   deleteExerciseSet: "deleteExerciseSet",
   createYogaSet:"createExcerciseSet",
   deleteYogaSet: "deleteExerciseSet",
   createMindActivity: 'createMindActivity',
   deleteMindActivity: 'deleteMindActivity',
   saveUserData:'saveUserData',
   workoutDateChanged:'workoutDateChanged',
   addCondition:'addCondition',
   productThumbnail:'productThumbnail',
   productImage:'productImage',
   productVideo:'productVideo',
   thumbnailLinkCategory:'thumbnailLinkCategory',
   createCategory:'createCategory',
   updateCategory:'updateCategory',
   deleteCategory:'deleteCategory',
   productBannerVideo:'productBannerVideo',
   productBannerImage:'productBannerImage',
   FeatureIcon:'FeatureIcon',
   typeBundle:'BUNDLE',
   typeProduct:'PRODUCT',
   addOn:'ADDON',
   call:'CALL',
   profileCall:'profileCall',
   paymentMember:'paymentMember',
   mergeContact:'mergeContact',

   // manage users
   createWellness: 'createWellness',
   inactiveWellness: 'inactiveWellness',
   completionProcess: 'completionProcess',
   
   // Dashboard
   updateCounsellingReason: 'updateCounsellingReason',
   updateFollowupReason: 'updateFollowupReason',

   // Manage Users
   editProfile:'editProfile',
   statusPayment:'statusPayment',
   transferSub:'transferSub',
   bulkExpire: 'bulkExpire',

   // Me
   updatePersona:'updatePersona',
   createUnavailability:'createUnavailability',

   // Organization
   createDepartment:'createDepartment',
   createDesignation:'createDesignation',
   createHandling:'createHandling',
   createHoliday:'createHoliday',
   assignPerson: 'assignPerson',
   singleAssignPerson: 'singleAssignPerson',
   updateRole:'updateRole',

   DELIVERABLE:'DELIVERABLE',
   removeItems:'removeItems',
   addChildCustomer:'addChildCustomer',
   addAddress:'addAddress',
   online:'ONLINE',
   offline:'OFFLINE',
   cod:'COD',
   user_data:'User Data',

   // Content Module
   createTransformation:"createTransformation",
   deleteTransformation:"createTransformation",
   createBanner:"createBanner",
   deleteBanner:"deleteBanner",
   createStoryCat:"createStoryCat",
   deleteStoryCat:"deleteStoryCat",
   createStory:"createStory",
   stories:"stories",
   deleteStory:"deleteStory",
   createPost: 'createPost',
   deletePost: 'deletePost',
   newEndDate: 'newEndDate',
   hourlyUnavailability: 'hourlyUnavailability',
   
   PausePlane:'PausePlane',
   createSubscriptionNew:'createSubscriptionNew',
   UpgradePlane:'UpgradePlane',
   createReferral:'createReferral',
   referalSalesPitching:'referalSalesPitching',
   rosterExport: 'rosterExport',
   bulkUpload: 'bulkUpload',
   addReason:'addReason',
   bookSlotConfirmed : 'bookSlotConfirmed',
   createRoster: 'createRoster',
   updateTimeAndCoach: 'updateTimeAndCoach',
   recommendDiet:'recommendDiet',
   updateShift:'updateShift',
   exportDiet :'exportDiet',
   exportExercise :'exportExercise',
   activateSub: 'activateSub',
   pendingAmount: 'pendingAmount',
   editWeightAndHeight:'editWeightAndHeight',
   recommendDietOption:'recommendDietOption',
   addFeedback:'addFeedback',
   updateDays:'updateDays',
   mealWiseCheck : 'mealWiseCheck',
   mealWiseCheckRow: 'mealWiseCheckRow',
   addDoctor: 'addDoctor',
   deleteLabel: 'deleteLabel',
   modifyPausePlane:'modifyPausePlan',
   cancelPause:'cancelPause',
   resumePause:'resumePause',
   todayCounselling:'todayCounselling',
   customerCounsellingConfimation:'customerCounsellingConfimation',
   rosterConfirmationCounselling:'rosterConfirmationCounselling',
   re_schedule: 're_schedule',
   salesDash: 'salesDash',
   recommendDietOptionV2:'recommendDietOptionV2',
   exchangeDiet : 'exchangeDiet',
   editAction:'editAction',
   editModule:'editModule',
   createReplicaModal:'createReplicaModal',
   upcomingSubs:'upcomingSubs',
   createChallenge: 'createChallenge',
   deleteChallenge: 'deleteChallenge',
   broadCastMessageWaring:'broadCastMessageWaring',
   broadCastMessageHeading:'Important! You cannot unsend this message',
   broadCastMessagesParaGraph:'Broadcast message is sent to multiple clients at same time. We recommend checking the message before sending'
}