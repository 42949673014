<ng-container *ngIf="isLoading">
  <div id="background"></div>
  <div id="logocontainer" (click)="close()">
    <!-- <div id="pelogo">Pe</div> -->
    <div id="pelogo"><img class="img-fluid" src="assets/images/loader/loader.png" alt=""></div>
    <div class="loader" style="left:2vh; top:0; height:2vh; width:0; animation:slide1 1s linear forwards infinite">
    </div>
    <div class="loader"
      style="right:0; top:2vh; width:2vh; height:0; animation:slide2 1s linear forwards infinite; animation-delay:0.5s">
    </div>
    <div class="loader" style="right:2vh; bottom:0; height:2vh; width:0; animation:slide3 1s linear forwards infinite">
    </div>
    <div class="loader"
      style="left:0; bottom:2vh; width:2vh; height:0; animation:slide4 1s linear forwards infinite; animation-delay:0.5s">
    </div>
  </div>
</ng-container>