import { Injectable } from '@angular/core';

declare var Moengage: any;

@Injectable({
  providedIn: 'root',
})
export class MoengageService {
  constructor() {}

  addUserName(name: string) {
    Moengage.add_user_name(name);
  }

  addFirstName(firstName: string) {
    Moengage.add_user_name(firstName);
  }

  addLastName(lastName: string) {
    Moengage.add_user_name(lastName);
  }

  addEmail(email: string) {
    Moengage.add_email(email);
  }

  addContact(phoneNumber: string) {
    Moengage.add_mobile(phoneNumber);
  }

  addGender(gender: string) {
    Moengage.add_gender(gender);
  }

  addUserBorn(birthday: string) {
    Moengage.add_birthday(birthday);
  }

  addUniqueId(uuid: string) {
    Moengage.add_unique_user_id(uuid);
  }

  updateUniqueId(id: string) {
    Moengage.update_unique_user_id(id);
  }

  moengageDataTracking(eventName:string,data:any){
    Moengage.track_event(eventName,data)
  }

  logout() {
    Moengage.destroy_session();
  }
}
