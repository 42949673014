import { Routes } from '@angular/router';
import { RoutePath } from '../router.config';
import { AuthGuard } from '../../guards/auth.guard';

export const ORGANIZATION_LAYOUT_ROUTE: Routes = [
  {
    path: RoutePath.manage_employees,
    canActivate: [AuthGuard],
    data: { pageId: ['manage-employees'], type: ['child'] },
    loadChildren: () => import('../../../pages/modules/organization/manage-employees/manage-employees.module').then((m) => m.ManageEmployeesModule),
  },
  {
    path: RoutePath.system_users,
    canActivate: [AuthGuard],
    data: { pageId: ['system-users'], type: ['child'] },
    loadChildren: () => import('../../../pages/modules/organization/system-users/system-users.module').then((m) => m.SystemUsersModule),
  },
  {
    path: RoutePath.manage_roles,
    canActivate: [AuthGuard],
    data: { pageId: ['manage-roles'], type: ['child'] },
    loadChildren: () => import('../../../pages/modules/organization/manage-roles/manage-roles.module').then((m) => m.ManageRolesModule),
  },
  {
    path: RoutePath.employee_configuration,
    canActivate: [AuthGuard],
    data: { pageId: ['employee-configuration'], type: ['child'] },
    loadChildren: () => import('../../../pages/modules/organization/employee-configuration/employee-configuration.module').then((m) => m.EmployeeConfigurationModule),
  },
  {
    path: RoutePath.roaster_configuration,
    canActivate: [AuthGuard],
    data: { pageId: ['roaster-configuration'], type: ['child'] },
    loadChildren: () => import('../../../pages/modules/organization/roaster-configuration/roaster-configuration.module').then((m) => m.RoasterConfigurationModule),
  },
]
