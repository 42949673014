import { Component, OnInit } from "@angular/core";
import { CONSTANTS_TEXT } from "src/app/core/const/app.constant";
import { LocalstorageService } from "src/app/core/service/localstorage.service";
import { NotificationService } from "src/app/core/service/navbar/notification.service";
import { ApiResponse, ViewNotification, pagination } from "src/app/core/shared/typings/app.typings";
import { Notification } from "src/app/core/shared/typings/notification.typing";

@Component({
    selector: 'app-view-notification',
    templateUrl: './view-notification.component.html',
    styleUrls: ['./view-notification.component.scss']
})
export class ViewNotificationComponent implements OnInit {
    notification: Notification[] = [];
    selectedFilter: string = 'today';
    noDataMessage: string = '';
    filteredNotifications: Notification[] = []; // Define filteredNotifications
  paginationData: pagination = { pageNumber: 1, pageSize: 10, length: 100 }
  userData:any = {};


    constructor(
        private _notificationService: NotificationService,
        private _localStorageService: LocalstorageService
    ) { }

    ngOnInit(): void {
    }

    onScrollDown() {
        this.paginationData.pageNumber = this.paginationData.pageNumber + 1
        this.getAllNotification();
    }

    getAllNotification() {
        const userDataString = localStorage.getItem('User Data');
        if (userDataString) {
          this.userData = JSON.parse(userDataString);
        } 
        let payload: ViewNotification = { page: this.paginationData.pageNumber, limit: this.paginationData.pageSize, }
        this._notificationService.getAllNotificationList(payload).subscribe((res: ApiResponse) => {
            if (res.success) {
                this.notification = res.data;
                this.setNoDataMessage();
            } else {
                this._localStorageService.showMessage(CONSTANTS_TEXT.error, res.message);
            }
        });
    }

    markAsDone(id: string) {
        this._notificationService.acknowledge(id).subscribe((response: ApiResponse) => {
            if (response.success) {
                console.log(response.data);
            } else {
                this._localStorageService.showMessage(CONSTANTS_TEXT.error, response.message);
            }
        });
    }

    getFilteredNotifications(): Notification[] {
        this.filteredNotifications = this.notification.filter(item => {
            if (this.selectedFilter === 'today') {
                return true;
            } else if (this.selectedFilter === 'read') {
                return item.acknowledged === true;
            } else if (this.selectedFilter === 'unread') {
                return item.acknowledged === false;
            } else {
                return true;
            }
        });

        this.setNoDataMessage();
        return this.filteredNotifications;
    }

    setNoDataMessage() {
        if (this.selectedFilter === 'read') {
            this.noDataMessage = this.filteredNotifications.length === 0 ? 'No read notifications available.' : '';
        } else if (this.selectedFilter === 'unread') {
            this.noDataMessage = this.filteredNotifications.length === 0 ? 'No unread notifications available.' : '';
        } else {
            this.noDataMessage = this.notification.length === 0 ? 'No data available.' : '';
        }
    }
}
