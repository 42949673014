<div class="modalDialog mx-sm-3">
    <h5 class="modal__title text-center text-dark mb-md-4 mb-3">Select Team</h5>
    <div class="dialogContent mt-2">
        <form class="modal_form_design" [formGroup]="form" (ngSubmit)='onSubmit()'>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group mb-3">
                        <label class="text-dark font-13 fw-600 mb-2">Select Team<span class="text-danger">*</span></label>
                        <ng-select [searchable]="false" [clearable]="false" formControlName="designation" [ngClass]="{ 'custom-invalid-ng-select': ((sub || fc.designation.touched) && fc.designation.errors)}">
                            <ng-option disabled selected value="">Choose One</ng-option>
                            <ng-option value="Dietitian">Dietitian</ng-option>
                            <ng-option value="Wellness">Wellness</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="pt-3 pb-4 text-end">
                <button-loader [loader]="loaderActive" [class]="'btn btn-primary shadow-1 font-14 fw-500 lh-22 py-2 px-md-4 px-3'" [text]="'Make Call'"></button-loader>
            </div>
        </form>
    </div>
</div>