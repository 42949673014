import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { getApiParamSet } from '../shared/function/function';
import { ICreateGuidlines, createdGuideline, userGuidelinesImport } from 'src/app/core/shared/typings/neeraj.guidelines.typing';
import { ApiURL } from './api';
import { ICallLogsFilter, paginationFilter } from '../shared/typings/app.typings';
import { ICreateMedical } from '../shared/typings/medical.reports.typings';
import { MakeCall } from '../shared/typings/profile.typings';

@Injectable({
    providedIn: 'root'
})
export class GuidelineService {

    constructor(private httpService: HttpService) { }

    createdGuidlines(body: createdGuideline) {
        return this.httpService.postAll(ApiURL.userGuidelines, body)
    }

    updateGuidlines(body: createdGuideline, id: string) {
        return this.httpService.putApi(ApiURL.userGuidelines, id, body)
    }

    getAllMedicalCondition() {
        return this.httpService.getAll(ApiURL.getMedicalCondition);
    }

    getUserGuidelines(params: { userId: string }) {
        return this.httpService.getAll(ApiURL.userGuidelines, getApiParamSet(params));
    }

    getMedicalConditionById(params: { medicalConditionId: string }) {
        return this.httpService.getAll(ApiURL.guidelines, getApiParamSet(params));
    }

    userGuidelinesImport(body:userGuidelinesImport){
        return this.httpService.postAll(ApiURL.userGuidelineImport, body);
    }

    deleteAllGuidlines(guidelineId: string) {
        return this.httpService.deleteOne(ApiURL.userGuidelines, guidelineId)
    }

    getListingMedicalCondition(params:paginationFilter){
        return this.httpService.getAll(ApiURL.getMedicalConditionList,getApiParamSet(params)) 
    }
    getAllListingMedicalCondition(){
        return this.httpService.getAll(ApiURL.getAllMedicalConditionList) 
    }
    postListingMedicalCondition(payload:ICreateMedical){
        return this.httpService.postAll(ApiURL.getMedicalConditionList,payload) 
    }
    putListingMedicalCondition(payload:ICreateMedical,id:string){
        return this.httpService.putApi(ApiURL.getMedicalConditionList,id,payload) 
    }

    getSingleGuidlines(params:{medicalCondition:string}){
        return this.httpService.getAll(ApiURL.getSingleGuidlines,getApiParamSet(params)) 
    }
    putSingleGuidlines(payload:ICreateGuidlines,id:string){
        return this.httpService.putApi(ApiURL.getSingleGuidlines,id,payload) 
    }

    postGuidlines(payload:ICreateGuidlines){
        return this.httpService.postAll(ApiURL.getSingleGuidlines,payload) 
    }

    getUserGuidlines(id:string){
        return this.httpService.getAll(`${ApiURL.getUserGuidlines}/${id}/guideline`) 
    }
    postUserGuidlines(payload:ICreateGuidlines){
        return this.httpService.postAll(ApiURL.postUserGuidlines,payload) 
    }
    putUserGuidlines(payload:ICreateGuidlines,id:string){
        return this.httpService.putApi(ApiURL.postUserGuidlines,id,payload) 
    }

    deleteUserGuidlines(id:string){
        return this.httpService.deleteOne(ApiURL.postUserGuidlines,id) 
    }

    // get call Logs Listing 
    getCallLogsListing(params:ICallLogsFilter){
        return this.httpService.postAll(ApiURL.callLogSGet,params) 
    }

    getSingleCallLisiting(body:ICallLogsFilter){
        return this.httpService.postAll(ApiURL.callLogSGet,body)
    }

    //make call 
    makeCall(body:MakeCall){
        const apiUrl = `${ApiURL.callLogSGet}/make-call`
        return this.httpService.postAll(apiUrl,body)
    }

    editCallTags(payload: string[], id: string) {
        const apiUrl = `${ApiURL.callLogSGet}/${id}/edit-tags`
        return this.httpService.patchOnlyUrlApi(apiUrl, {tags:payload})
    }
}
