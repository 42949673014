import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs';
import { CONSTANTS_TEXT } from 'src/app/core/const/app.constant';
import { GuidelineService } from 'src/app/core/service/guidelines.service';
import { LocalstorageService } from 'src/app/core/service/localstorage.service';
import { ApiResponse } from 'src/app/core/shared/typings/app.typings';
import { MakeCall } from 'src/app/core/shared/typings/profile.typings';

@Component({
  selector: 'app-call-popup',
  templateUrl: './call-popup.component.html',
  styleUrls: ['./call-popup.component.scss']
})
export class CallPopupComponent implements OnInit {

  form: FormGroup;
  customerId: string | null;
  loaderActive: boolean = false;
  sub: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private dialogRef: MatDialogRef<CallPopupComponent>,
    private fb: FormBuilder,
    private _guidelineService: GuidelineService,
    private _localStorage: LocalstorageService,
  ) {
  }

  ngOnInit(): void {
    if(this.modalData?.data){
      this.customerId = this.modalData?.data?.id;
    }
    this.initializeForm();
  }

  initializeForm() {
    this.form = this.fb.group({
      designation: ['', [Validators.required]]
    })
  }

  get fc(): any {
    return this.form.controls;
  }

  // submit 
  onSubmit() {
    this.sub = true;
    if (this.form.invalid) {
      return;
    }
    let payload: MakeCall = this.form.value;
    payload.customerId = this.customerId;
    this.callApi(payload);
  }

  // call api integrate 
  callApi(payload:MakeCall) {
    this.loaderActive = true;
    this._guidelineService.makeCall(payload).pipe(finalize(() => { this.loaderActive = false; })).subscribe((res: ApiResponse) => {
      if (res.success) {
        let successMessage = "Successfully connected with the customer!";
        if (res.message) res.message = successMessage;
        this._localStorage.showMessage(CONSTANTS_TEXT.success, successMessage);
        this.dialogRef.close({status: true, type: CONSTANTS_TEXT.call})
      }
      else {
        this._localStorage.showMessage(CONSTANTS_TEXT.error, res.message)
      }
    })
  }
}
