import { Injectable } from "@angular/core";
import { HttpService } from "../http.service";
import { ApiURL } from "../api";
import { NavBarMember} from "../../shared/typings/app.typings";
import { getApiParamSet } from "../../shared/function/function";

@Injectable({
    providedIn: 'root'
})

export class NavBarService{

    constructor(private httpService: HttpService) { }

    // get member list data
    getMemberData(body:NavBarMember) {
        return this.httpService.getAll(ApiURL.memberSearchBar,getApiParamSet(body));
    }

    getCustomerData(body:{customerPhoneNumber:string}){
        return this.httpService.postAll(ApiURL.customerView,body)
    }

    // send otp 
    sendOtp(body:{countryCode:string,phoneNumber:string,otpMethod:string}){
        return this.httpService.postAll(ApiURL.resendOTP,body)
    }

    //reset user limit
    resetUserLimit(body:{countryCode:string,phoneNumber:string}){
        return this.httpService.postAll(ApiURL.userLimit,body)
    }

    // reset store data 
    resetStore(body:{customer_id?:string}){
        return this.httpService.postAll(ApiURL.resetStore, body)
    }
}