export enum StatusCode {
  Unknown = 0,
  OK = 200,
  Created = 201,
  BadRequest = 400,
  ValidationCode = 401,
  InternalServerError = 500,
  NotFound = 404,
  Conflict = 409,
  Forbidden = 403,
  NonStandard =402
}

export const INTERNET_CONNECTION_ERROR = {
  servererror: 'Internal Server Error',
  internetcheck: 'please check your Internet Connection',
  someerror: 'Some Error Occured'
}

export const BUCKET_MODULES_ENUM = {
  RECIPE_MODULE: 'RECIPE_MODULE',
  EXERCISE_MODULE_IMAGE: 'EXERCISE_MODULE',
  EXERCISE_MODULE_VIDEO: 'EXERCISE_MODULE_VIDEO',
  EXERCISE_MODULE_THUMBNAIL: 'EXERCISE_MODULE_THUMBNAIL',
  YOGA_MODULE_IMAGE: 'YOGA_MODULE',
  YOGA_MODULE_VIDEO: 'YOGA_MODULE_VIDEO',
  YOGA_MODULE_THUMBNAIL: 'YOGA_MODULE_THUMBNAIL',
  MIND_MODULE_IMAGE: 'MIND_MODULE',
  MIND_MODULE_VIDEO: 'MIND_MODULE_VIDEO',
  MIND_MODULE_THUMBNAIL: 'MIND_MODULE_THUMBNAIL',
  MIND_MODULE_AUDIO: 'MIND_MODULE_AUDIO',
  REPORT_MODULE_PDF: "REPORT_MODULE_PDF",
  STORY_MODULE_IMAGE: 'STORY_MODULE_IMAGE',
  TRANSFORMATION_MODULE_IMAGE: 'TRANSFORMATION_MODULE_IMAGE',
  BANNER_MODULE_IMAGE: 'BANNER_MODULE_IMAGE',
  POST_MODULE_IMAGE: 'POST_MODULE_IMAGE',
  PRODUCT_MODULE_IMAGE :'PRODUCT_MODULE_IMAGE',
  PRODUCT_MODULE_VIDEO:'PRODUCT_MODULE_VIDEO',
  PRODUCT_MODULE_THUMBNAIL:'PRODUCT_MODULE_THUMBNAIL',
  PRODUCT_MODULE_BANNER:'PRODUCT_MODULE_BANNER',
  BUNDLE_MODULE_IMAGE:'BUNDLE_MODULE_IMAGE',
  BUNDLE_MODULE_VIDEO:'BUNDLE_MODULE_VIDEO',
  BUNDLE_MODULE_THUMBNAIL:'BUNDLE_MODULE_THUMBNAIL',
  BUNDLE_MODULE_BANNER:'BUNDLE_MODULE_BANNER',
  OFFLINE_PAYMENT_PROOF:'OFFLINE_PAYMENT_PROOF',
  CATEGORY_MODULE_THUMBNAIL:'CATEGORY_MODULE_THUMBNAIL',
  PRODUCT_FEAT_ICON:'PRODUCT_FEAT_ICON',
  BUNDLE_FEAT_ICON:'BUNDLE_FEAT_ICON',
  REPORT_MODULE_IMAGE: 'REPORT_MODULE_IMAGE',
  CHALLENGES_MODULE_IMAGE: 'CHALLENGES_MODULE_IMAGE',
  APPROVALS_NOTES:'APPROVALS_NOTES'
}

export enum ActionTypes {
  LISTING_ACTION = 'LISTING_ACTION', // status change and all actions in list)
  BUTTON = 'BUTTON', // (create , update and all)
  ACTION = 'ACTION', // (action button)
  FILTER = 'FILTER', // (all filters)
}

export enum ROSTER_CATEGORY_ENUMS {
  WM = 'WEIGHT_MANAGEMENT', 
  DM = 'DISEASE_MANAGEMENT',
  Diet = 'DIET', 
  EXERCISE = 'EXERCISE',
  MIND = 'MIND',
  YOGA = 'YOGA',
}

 

//pending reason
export const COUNSELLING_PENDING_REASON =[
  'CALL_LATER_TODAY',
  'RE_SCHEDULED',
  'SPECIFIC_DIETITIAN',
  'SKILL_SET',
  'NOT_RESPONDING'
]

export const BearerToken = {
  devToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidXNyXzAxSFRDV1BEWlk5UDVKN1hETUtOR1owRzhXIiwiZG9tYWluIjoiYWRtaW4iLCJpYXQiOjE3MTk1NzIyNzQsImV4cCI6MjkxOTY1ODY3NH0.uaJ7f_hTrKGDNiHYb5U227EplrXf-qVUrKWes5uDhks',
  prodToken:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidXNyXzAxSFhUWFZKWEdOSDFBQVpRTTBGNTJBWENWIiwiZG9tYWluIjoiYWRtaW4iLCJpYXQiOjE3MTg4ODExNzIsImV4cCI6MTkxODk2NzU3Mn0.lYjC4U0XpfBQ6ueIR0Hh2cSwX1KmjEl4ReNTP_y0RCk'
}
