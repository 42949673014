import { Component, OnDestroy } from '@angular/core';
import { getLoginSuccessfully$, setCountMessage, setEmployeesList, setNotificationCount, setReportTreeList, setReporteeList } from './core/shared/@subject/subjects';
import { AuthService } from './core/service/auth.service';
import { MoengageService } from './core/service/commonService/moengage.service';
import { NotificationService } from './core/service/navbar/notification.service';
import { SocketService } from './core/service/commonService/socket.service';
import { PermissionService } from './core/shared/function/permissionHandler';
import { LocalstorageService } from './core/service/localstorage.service';
import { ChatService } from './core/service/chat/chat.service';
import { formatPhoneNumber } from './core/shared/function/function';
import { ChatClientService } from 'stream-chat-angular';
import { interval, Subscription } from 'rxjs';
import { ApiResponse } from './core/shared/typings/app.typings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'admin_panel';
  userDetail: any = {};
  userData: any = {};
  // to check if access type is self
  isSelf:boolean = false;
  private intervalSubscription: Subscription;

  constructor(
    private authService: AuthService,
    private notificationService: NotificationService,
    private moengageService: MoengageService,
    private socketService: SocketService,
    private permissionService: PermissionService,
    private localStorage : LocalstorageService,
    private chatService: ChatService,
    private client: ChatClientService
  ) {
    if (this.authService.loggedIn()) {
      this.getAllEmployee();
      this.getChatToken();
      this.getAllReportee();
      this.getAllReportTree();
      this.permissionService.mapSideBarMenu()
      // this.streamcountCheck();
      // this.getNotificationCount()
    }
    getLoginSuccessfully$.subscribe((res:boolean) =>{
      if(res){
        this.getAllEmployee();
        this.getChatToken()
        this.getAllReportee()
        this.getAllReportTree();
        // this.getNotificationCount();
        // this.streamcountCheck();
      }
    })
  }

 streamcountCheck(){
    this.channelCount();
    this.intervalSubscription = interval(60000).subscribe(count => {
      this.channelCount();
    })
  }

ngOnDestroy() {
}

 channelCount(){
  this.chatService.getChannelCount().subscribe((res:ApiResponse)=>{
    if(res.success && res.data && res.data.channels && res.data.channels.length){
      setCountMessage(res.data.channels.length);
      this.chatService.channelIdsMap(res.data.channels)
    }else{
      setCountMessage(0)
      this.chatService.channelIdsMap([])
    }
  })
  // const userDetails = this.localStorage.getItem('User Data');
  // if(userDetails){
  //   const userId = (JSON.parse(userDetails)).employeeDetails.code
  //     const channels = await this.client.chatClient.getUnreadCount("EMP" + userId);
  //     setCountMessage(channels.channels.length)
  // }
  }

  ngAfterViewInit() {
    if (this.authService.loggedIn()) {
      // console.log(this.userDetail, 'moengage userDetails');
      const userDataString = this.localStorage.getItem('User Data');
      if (userDataString) {
        this.userData = JSON.parse(userDataString);
        // console.log(this.userDetail,this.userData, 'moengage userDetails');
      } else {
        // console.log('User data not found in localStorage');
      }
      this.addMoengageDetails();
      // this.socketService.initializeSocket(this.userData?.employeeDetails.id)
    }
  }

  // get chat token 
  getChatToken() {
    const userDataString = this.localStorage.getItem('User Data');
    let userData;
    if (userDataString) {
      userData = JSON.parse(userDataString);
      // userData.employeeDetails.isGetStreamRegistered && 
        if(!this.localStorage.getItem("streamToken")) {
          this.chatService.getToken().subscribe((result) => {
            this.localStorage.setItem("streamToken", result.data.token)
          })
        }
      }
    
  }

  // Get all Employee list
  getAllEmployee() {
    this.authService.getEmployeeData().subscribe((result) => {
      setEmployeesList(result.data)
    })
  }

    // Get all Employee list
    getAllReportee() {
      this.isSelf = false
      const userDataString = this.localStorage.getItem('User Data');
      let id = ''
      if (userDataString) {
        this.userData = JSON.parse(userDataString);
        if (this.userData?.employeeDetails?.accessType === 'REPORTING') {
          id = this.userData.employeeDetails.id
          this.isSelf = false
        }else {
          this.isSelf = true
        }
      } else {
        // console.log('User data not found in localStorage');
      }
      // if access type is self then api call won't happen
      if(!this.isSelf){
        this.authService.getReporteesData(id,false).subscribe((result) => {
          // this.authService.getReporteesData(this.userData?.employeeDetails.id,false).subscribe((result) => {
            setReporteeList(result.data)
          })
      }
     
    }
    getAllReportTree() {
      this.isSelf = false
      const userDataString = this.localStorage.getItem('User Data');
      let id = ''
      if (userDataString) {
        this.userData = JSON.parse(userDataString);
        if (this.userData?.employeeDetails?.accessType === 'ALL') {
          id = 'dbb7670b-6117-4136-a704-95588ab61088'
          this.isSelf = false
        } else if (this.userData?.employeeDetails?.accessType === 'REPORTING') {
          this.isSelf = false
          id = this.userData.employeeDetails.id
        }else if (this.userData?.employeeDetails?.accessType === 'SELF') {
          this.isSelf = true
        }
      } else {
        // console.log('User data not found in localStorage');
      }
      // if access type is self then api call won't happen

      if(!this.isSelf){
        this.authService.getReporteesData(id,true).subscribe((result) => {
          // this.authService.getReporteesData(this.userData?.employeeDetails.id,true).subscribe((result) => {
            if(result.success){
              setReportTreeList([result.data])
            }
          })
      }
    }

  addMoengageDetails() {
    this.userDetail.firstName = this.userData?.employeeDetails?.firstName;
    this.userDetail.lastName = this.userData?.employeeDetails?.lastName;
    this.userDetail.username = this.userData?.employeeDetails?.displayName;
    this.userDetail.email = this.userData?.employeeDetails?.email;
    this.userDetail.phone = formatPhoneNumber(this.userData?.employeeDetails?.countryCode , this.userData?.employeeDetails?.phoneNumber)
    this.userDetail.uuid = this.userData?.employeeDetails?.email;
    this.moengageService.addUserName(this.userDetail.username);
    this.moengageService.addFirstName(this.userDetail.firstName);
    this.moengageService.addLastName(this.userDetail.lastName);
    this.moengageService.addEmail(this.userDetail.email);
    this.moengageService.addContact(this.userDetail.phone);
    this.moengageService.addUniqueId(this.userDetail.uuid);
    this.moengageService.moengageDataTracking('panel data tracking', this.userDetail)
  }

  // get notification count
  getNotificationCount() {
    this.notificationService.getPushNotificationCount().subscribe((result) => {
      setNotificationCount(result.data)
    })
  }

}
