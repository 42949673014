import { Routes } from '@angular/router';
import { RoutePath } from '../router.config';
import { AuthGuard } from '../../guards/auth.guard';

export const CONTENTS_LAYOUT_ROUTE: Routes = [
    {
        path: RoutePath.transformations,
        canActivate: [AuthGuard],
        data:{ pageId: ['transformations'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/contents/transformations/transformations.module').then((m) => m.TransformationsModule),
    },
    {
        path: RoutePath.banners,
        canActivate: [AuthGuard],
        data:{ pageId: ['banners'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/contents/banners/banners.module').then((m) => m.BannersModule),
    },
    {
        path: RoutePath.stories,
        canActivate: [AuthGuard],
        data:{ pageId: ['stories'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/contents/stories/stories.module').then((m) => m.StoriesModule),
    },
    {
        path: RoutePath.posts,
        canActivate: [AuthGuard],
        data:{ pageId: ['posts'], type:['child'] },
        loadChildren: () => import('../../../pages/modules/contents/posts/posts.module').then((m) => m.PostsModule),
    }
]