import { Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { setNotificationCount } from '../../shared/@subject/subjects';

declare var Moengage: any;

@Injectable({
  providedIn: 'root',
})
export class SocketService {
    
  socket: Socket

  // initialize socket
  initializeSocket(employeeId:string) {
    if(environment.url){
      this.socket = io(environment.url,{transports: ['websocket'],});
      this.socket.on(`employee-push-count-${employeeId}`, (count: number) => {
        console.log('count:', count);
        setNotificationCount(count)
      });
    }
  }

  disconnectSocket(){
    this.socket.disconnect()
  }
}